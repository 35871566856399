<template>
  <section :id="'editorial-content-' + pagePosition" class="title_text editorial-content">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <Observer name="fade-up">
            <h2 :class="'h2 title text-' + item.title_align " v-html="item.title" v-if="item.title"></h2>
            <div v-html="item.text" :class="'wysiwyg text-' + item.text_align " data-aos="fade-in" v-if="item.text"></div>
          </Observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'title_text',
  data() {
    return {
      flag: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.hash && this.$route.hash === '#editorial-content-' + this.pagePosition && !this.flag && window !== 'undefined') {
        this.flag = true
        let bodyRect = document.body.getBoundingClientRect()
        let elemRect = document.querySelector(this.$route.hash).getBoundingClientRect()
        let offset = elemRect.top - bodyRect.top
        let decal = window.scrollY > offset ? 200 : 120
        setTimeout(() => {
          window.scrollTo({
            top: offset - decal,
            behavior: 'smooth'
          })
        }, 200)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    margin: 10rem 0;
    color: var(--zenith-component-text);

    &.editorial-content + .editorial-content {
      margin-top: -6rem;

      @include bp(max md) {
        margin-top: -2rem;
      }
    }

    @include bp(max md) {
      margin-bottom: 6rem;
      margin-top: 6rem;
    }
  }

  .obs-fade-up {

    .wysiwyg {
      opacity:0;
      transform: translateY(8rem);
    }
  }
  .title {
      font-weight: 500;
      font-size: em(26);
      margin-bottom: 2rem;
      color: var(--zenith-theme-primary);

      @include bp(max md) {
        font-size: em(21);
        line-height: em(28, 21);
        letter-spacing: em(.2, 21);
      }
  }

  .wysiwyg {
    @include appear(100ms);

    /deep/ ul, /deep/ ol {
      padding: 0;
      margin-bottom: 2rem;
      font-size: em(20);
      line-height: 1.8;
      letter-spacing: em(0.2, 20);
      list-style: none;

      li {
        position: relative;
        padding: 0 0 0 2rem;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: '-';
        }
      }

      @include bp(max md) {
        font-size: em(18);
        font-weight: normal;
        line-height: em(28, 18);
        letter-spacing: em(.2, 18);
      }
    }

    /deep/ p {
      margin-bottom: 2rem;
      font-size: em(20);
      line-height: 1.8;
      letter-spacing: em(0.2, 20);

      @include bp(max md) {
        font-size: em(18);
        font-weight: normal;
        line-height: em(28, 18);
        letter-spacing: em(.2, 18);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
