<template>
  <section class="basic_page_title relative">
    <Observer name="fade-up">
      <div class="title-container text-center">
        <h1 class="a11y-sr-only" v-html="item.title" v-if="item.title"></h1>
        <p class="a11y-sr-only" v-html="item.subtitle" v-if="item.subtitle"></p>
        <h1 data-splitting aria-hidden="true" class="h1 title -upper" v-html="item.title" v-if="item.title"></h1>
        <div data-splitting aria-hidden="true" class="subtitle h5 -upper" v-html="item.subtitle" v-if="item.subtitle"></div>
      </div>
      <!-- <button @click="scrollDown" type="button"><i class="icon-back-copy"></i></button> -->
    </Observer>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  name: 'basic_page_title',
  mixins: [ReadyHook],

  mounted() {
    this.$nextTick(() => {
      if(process.browser) {
        const Splitting = require('splitting');
        Splitting();
      }
    });
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  .-icons .title {
    display: inline;
    font-size: em(51);
    font-weight: normal;
    color: var(--zenith-white) !important;
    background: var(--zenith-theme-primary) !important;
    box-shadow: -2px 0px 0px 6px var(--zenith-theme-primary);
  }

  section {
    margin: 10rem 0;
    height: calc(60vh - 120px - 20rem);

    @include bp(max md) {
      margin-bottom: 2rem;
      margin-top: 2rem;
      height: calc(50vh - 120px);
    }
  }
  .obs {
    height: 100%;
    position: relative;
  }
  .obs-fade-up {

    .title {
      /deep/ .char {
        opacity: 0;
        transform: translateY(-8rem);
      }
    }
    .subtitle {
      /deep/ .char {
        opacity: 0;
        transform: translateY(8rem);
      }
    }
  }

  .title-container {
    display: inline-block;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:100%;
    color: var(--zenith-theme-primary);
  }
  .title {
    line-height: 1;
    padding: 0;

    /deep/ .word {
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
    }

    /deep/ .char {
      display: inline-block;
      vertical-align: top;
      @include appearTitle();
    }

  }

  .subtitle {
    line-height: 1;
    padding: 0;

    /deep/ .word {
      display: inline-block;
      overflow: hidden;
      vertical-align: top;
    }
    /deep/ .char {
      display: inline-block;
      vertical-align: top;
      @include appear(100ms);
    }
  }

  button {
    width: 4.8rem;
    color: var(--zenith-theme-primary);
    padding: 1.3rem 0;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0);
    background: transparent;
    margin-top: 10rem;

    position: absolute;
    left: 50%;
    bottom: 12rem;

    @include bp(max md) {
      bottom: 5rem;
      display: none;
    }

    i {
      display: block;
      font-size: em(20);
      line-height: 2rem;
      animation: bouncing 1s infinite ease-in-out;
    }

    &:hover, &:active, &:focus {
      background: var(--zenith-white);
      color: var(--zenith-theme-primary);
    }
  }
</style>
